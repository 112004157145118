import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay'; // 导入 Autoplay 模块的样式
import { Pagination, Navigation, Scrollbar, Autoplay } from 'swiper/modules';
import './agent.scss';

const AgentSwiper = ({ images = [
  {
    src: '/agent/swiper1.png', alt: 'Overlay Style',
    title: '通用旗舰GPU(超算+智算+通用计算)',
    desc: [
      { content: "架构及指令集,全自研GPGPU架构及指令集 ，具有广泛通用性和易用性" },
      { content: "算力,多精度混合算力 ，广泛支持智算和通用计算场景需求" },
      { content: "显存与缓存架构,大容量高带宽显存与多级缓存架构 ，高效数据通道系统" },
      { content: "多卡互联技术,MarsLink多卡互联技术，支持计算集群扩展" },
      { content: "内存,32 ×64GB 2RX4 DDR5-4800 RDI MM内存" },
      { content: "硬盘1,2 ×960GB 6G SATA 3.5in RI PM893 SSD UCC通用硬盘模块" },
      { content: "硬盘2,4 ×7.68TB PCle*Gen4 X4 NVMe U.2 3.5in   RI PM9A3 SSD UCC通用硬盘模块" }
    ]
  },
  {
    src: '/agent/swiper2.png',
    alt: 'Shadow Style',
    title: '数据采集及存储与计算训练',
    desc:[
      {
        content:'l 高性能大数据计算引擎，支持多源异构数据接入，实现PB级数据处理和建模。'
      },
      {
        content:'l 数据统计及异常自动计算，确保数据质量与特征管理。'
      },
      {
        content:'l 从数据到模型的拖拽式交互，图形化挖掘数据价值，降低技术门槛。'
      },
      {
        content:'l 模型超参及指标可视化对比，加速参数调整与优化。'
      },
      {
        content:'支持主流深度学习与机器学习框架，如逻辑回归、随机森林、CNN、RNN等算法，满足多样化计算需求。'
      }
    ]
  },
  {
    src: '/agent/swiper3.png',
    alt: 'Celay+S Style',
    title: '（大数据模型）核心组件',
    desc:[
      {
        content:'检索增强（RAG）技术：结合行业知识库，RAG技术通过动态检索外部知识（如最新政策、行业报告）增强模型推理能力。例如，在政务场景中，AI模型实时检索法规库辅助公文分类，准确率提升至90%以上。在AIGC领域，RAG支持电商营销内容生成时自动关联商品知识库，确保推荐文案与产品特性高度匹配。'
      }
    ]
  },
  {
    src: '/agent/swiper4.png',
    alt: 'Overlay Style',
    title: '数据源接入与知识训练',
    desc:[
      {
        content:'提供低代码平台，支持企业私域数据快速接入、自动化标注及安全加密。一键完成从部署到推理服务上线，大幅缩短开发周期。'
      },
      {
        content:'低代码平台的可视化编排界面，支持拖拽式组件配置与自然语言定义逻辑，开发者通过流程化配置完成业务处理逻辑，显著降低编码复杂度。'
      },
      {
        content:'实体识别与分类、去重/同义（人工标注词+算法训练+LLM辅助结合），提升数据质量。'
      },
      {
        content:'安全管理系统交互对接，确保数据安全与合规性。'
      }
    ]
  },
] }) => {

  return (
    <div className="agent-swiper">
      <Swiper
        loop={true}
        autoplay={{
          delay: 20000,
          disableOnInteraction: false,
        }}
        // centeredSlides
        modules={[Navigation, Pagination, Scrollbar, Autoplay]} // 添加 Autoplay 模块
        spaceBetween={1} // 调整幻灯片间距
        slidesPerView={1} // 同时展示3个幻灯片
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className='agent-swiper'>
              <img src={image.src} alt={image.alt} className="carousel-image" />
              <div className='agent-swiper-card'>
                <div className='swiper-card-title'>{image.title}</div>
                <ul>
                  {
                    image.desc && image.desc.map((item, i) => <li key={i} className='swiper-card-desc'>{item.content}</li>
                      
                    )
                  }
                </ul>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default AgentSwiper;