import { useState } from 'react';
import '../../scss/children.scss'
import { Grid, Item } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Swiper from './AgentSwiper';
import AgentCard from './agentCard'
import AgentSwiper from './AgentSwiper';

const About = () => {
    //   const agent = window.config.agent || {}
    const [buttonId, setButtonId] = useState(0)
    const navigate = useNavigate();
    const agent = {
        // hero部分
        title: 'AI 智能一体机',
        subTitle: '精工铸造，全能低代码平台，融汇流程、报表、模型设计精华，赋能高效构建，智领业务数据生态自进化。',
        button: [{ name: '总架构', id: 0 }, { name: 'AI芯片', id: 1 }, { name: 'AI算法引擎', id: 2 }, { name: 'AI Agent', id: 3 }],
        content: [
            {
                title: '拖拉拽搭建：',
                option: 'AI人工智能一体机是一款集成了高性能国产AI GPU芯片与多GPU集群架构的先进设备，旨在提供高效、安全、灵活的AI解决方案。该一体机支持动态算力切割与资源池化，单机可实现百亿级模型实时推理。内置行业知识库与联邦学习系统，结合检索增强（RAG）和低代码平台，支持企业私域数据快速接入、自动化标注及安全加密，快速完成从部署到推理服务上线。',
                img: "/agent/zongjiagou.mp4",
                icon: '/agent/icon1.png'
            },
            {
                title: '报表功能多样化：',
                option: 'AI国产芯片是由中国本土科技企业自主研发的新一代人工智能计算核心，采用先进制程工艺与异构计算架构，突破传统芯片能效瓶颈。该芯片集成深度学习加速引擎与自适应指令集，可高效支持云端训练、边缘推理及端侧实时决策等全场景AI应用，具备高算力密度、低功耗及强安全加密特性。其核心技术覆盖自主指令集、存算一体设计及软硬协同优化，广泛应用于智慧城市、工业质检、自动驾驶等领域，助力中国AI产业生态摆脱外部技术依赖，加速关键领域智能化升级与国产化替代进程。',
                img: "/agent/ai_xinpian.gif",
                icon: '/agent/icon2.png'
            },
            {
                title: '可扩展定制开发：',
                option: 'AI算法引擎作为智能系统的核心驱动模块，深度融合检索增强生成（RAG）技术，通过动态知识库与生成模型的协同优化，大幅提升推理准确性与场景适应性。其架构集成多模态语义理解、实时数据检索及上下文感知能力，支持从海量结构化与非结构化数据中精准提取信息，并结合生成式模型输出逻辑连贯、知识可信的决策结果。基于自监督学习框架与轻量化模型压缩技术，该引擎可在低资源环境下实现高并发、低延迟的端到端处理。',
                img: "/agent/suanfa.gif",
                icon: '/agent/icon3.png'
            },
            {
                title: '高效数据资源利用',
                option: 'AI Agent（人工智能代理）是基于自主决策与动态目标推理的智能实体，通过多模态感知、环境交互与持续学习机制，实现复杂场景下的任务分解与闭环执行。其核心架构融合强化学习、知识图谱与自然语言理解技术，具备上下文记忆、意图推理及自适应策略优化能力，可跨平台调度资源、实时响应外部变化并生成可解释的行动路径',
                img: "/agent/AI_agent.mp4",
                icon: '/agent/icon4.png'
            },
        ],
        //   轮播图部分
        swiperTitle: '多种场景  开箱即用',
        swiper: [
            { src: '/children/swiper7.png', alt: 'Overlay Style' },
            { src: '/children/swiper8.png', alt: 'Shadow Style' },
            { src: '/children/swiper9.png', alt: 'Celay+S Style' },
            { src: '/children/swiper10.png', alt: 'Overlay Style' },
        ],
        advantage: [
            {
                src: '/agent/adv1.png',
                title: '全栈软硬协同架构',
                desc: '深度适配DeepSeek系列模型，支持训练/推理任务混合负载，硬件利用率达90%+'
            },
            {
                src: '/agent/adv2.png',
                title: '开箱即用的一体化交付',
                desc: '内置垂直领域大模型，支持API一键调用，从拆箱到服务发布全流程开发周期缩短70%'
            },
            {
                src: '/agent/adv3.png',
                title: '极致能效与可靠性',
                desc: '支持2000W TDP持续负载，硬件冗余（双电源/网络模块）+软件自愈'
            },
            {
                src: '/agent/adv4.png',
                title: '安全可控的数据闭环',
                desc: '本地化部署支持敏感数据零外传，结合加密传输与权限隔离'
            }
        ]
    }
    const routerHandler = () => {
        navigate('/contactus');
    }
    const clickHandler = (res) => {
        setButtonId(res)
    }
    return (
        <div id='screen'>
            <div className='screen-image'>
                <img src='/agent/agent_banner.png' alt='screnn-home' />
                <img src='/agent/agent_banner_icon.png' className='agent-icon'></img>
                <img src='/agent/agent_banner_icon1.png' className='agent-icon1'></img>
                <div className='screen-image-position'>
                    <p className='screen-image-position-p'>{agent.title}</p>
                    {/* <p className='screen-image-position-text'>{agent.subTitle}</p> */}
                    <div className='screen-image-position-button cusor-point' onClick={() => { routerHandler() }}>
                        申请体验
                    </div>
                </div>
            </div>
            <div className='screnn-show'>
                <Grid container spacing={2} direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className='list-card'
                >
                    <Grid item md={5}>
                        <div className='screnn-card'>
                            <img src="/agent/Link.png" alt="" />
                            <div className='card-content'>
                                公司推出升级版AI一体
                                机，昇腾算力加持并接入deepseek模型，覆盖医疗，政务等垂直领域
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={7}>
                        <div className='container-content'>
                            <div className='screnn-conent'>
                                <div className='rt-card'>
                                    <div className='rt-time1'>
                                        <div className='rt-day1'>26</div>
                                        <div className='rt-year'>2025-02</div>
                                    </div>
                                    <div className='rt-content'>
                                        <div className='content-top'>深度融合DeepSeek，支持企业私域数据快速接入</div>
                                        <div className='content-desc'>内置API网关，完成多模态工具链集成</div>
                                    </div>
                                </div>
                            </div>
                            <div className='screnn-conent'>
                                <div className='rt-card'>
                                    <div className='rt-time1'>
                                        <div className='rt-day1'>20</div>
                                        <div className='rt-year'>2024-12</div>
                                    </div>
                                    <div className='rt-content'>
                                        <div className='content-top'>AI智能体平台发布：AI赋能 行业应用，让数据“开口说话”</div>
                                        <div className='content-desc'>多领域作用: 数据分析/智能推荐/语音助手/自动化客服...</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className='screnn-show-top'>
                    <Grid container>
                        {
                            agent.button && agent.button.map((item, index) => {
                                return <Grid item key={index}>
                                    <div className={item.id === buttonId ?
                                        'screen-show-button button-arrow cusor-point' : 'screen-show-button cusor-point'}
                                        onClick={() => clickHandler(index)} key={index}>
                                        {item.name}
                                    </div>
                                </Grid>
                            })
                        }
                    </Grid>
                </div>
                {
                    agent.content && agent.content[buttonId] && <div className='screnn-show-bot'>
                        <Grid container>
                            <Grid item md={5}>
                                <div className='screnn-show-bot-left'>
                                    <div className='screnntop'>
                                        <img src={agent.content[buttonId].icon} alt='screnn-vector' />
                                    </div>
                                    <div className='scrennbottom'>
                                        {/* <div className='yfjs-title-white'>{agent.content[buttonId].title}</div> */}
                                        <div className='yfjs-text-white'>
                                            {agent.content[buttonId].option}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={7}>
                                <div className='screnn-show-bot-right' key={buttonId}>
                                    <div className='screnn-show-bot-right-border'>
                                        {
                                            buttonId == '1' ? 
                                            <img  width="100%" height="auto" src={agent.content[buttonId].img} alt="" />
                                            :
                                            buttonId == '2' ? 
                                            <img  width="100%" height="auto" src={agent.content[buttonId].img} alt="" />
                                            :
                                            <video width="100%" height="auto" loop autoPlay muted>
                                                <source src={agent.content[buttonId].img} type="video/mp4" />
                                            </video> 
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                }

            </div>

            <div className='screnn-swiper'>
                <div className='font-title'>
                    {agent.swiperTitle}
                </div>
                <div className='screnn-swiper-content'>
                <AgentSwiper></AgentSwiper>
                    {/* <Swiper images={agent.swiper} /> */}
                </div>
            </div>
            <div className='advantage'>
                <div className="title">优势</div>
                <Grid container spacing={2} direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {
                        agent.advantage && agent.advantage.map((item, index) => {
                            return <Grid item md={3}>
                                <div className='advantage-card'>
                                    <div>
                                        <img src={item.src} className='adv-icon' alt="" />
                                    </div>
                                    <div className='adv-title'>
                                        {item.title}
                                    </div>
                                    <div className='adv-desc'>
                                        {item.desc}
                                    </div>
                                </div>
                            </Grid>
                        })

                    }

                </ Grid>
            </div>
            <AgentCard></AgentCard>
        </div>
    );
};

export default About;
