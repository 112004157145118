import React, { useState } from 'react';
import '../../scss/agentCard.scss';

const images = [
    {
        id: 1,
        icon: '/agent/zhengwu.png',
        src: '/agent/zhengwuBg.png',
        alt: '政务',
        title: 'AI+ 政务',
        description: '基于DeepSeek大模型的政务AI一体机，支持本地化部署及多场景应用，如民情处理、公文生成等，提升政务效率。'
    },
    {
        id: 2,
        icon: '/agent/gongye.png',
        src: '/agent/gongyeBg.png',
        alt: '工业',
        title: 'AI+ 工业',
        description: '通过集成边缘计算、机器视觉与自适应控制技术，AI一体机实现工业设备端到端的智能化闭环，赋能产线实时质检、设备预测性维护及能耗优化等场景。其软硬协同设计突破传统算力瓶颈，结合低延迟、高可靠的数据处理能力，显著提升生产精度与良品率，同时通过模块化部署降低企业智能化改造门槛，驱动工业制造向柔性化、无人化与全流程自决策转型。'
    },
    {
        id: 3,
        icon: '/agent/nengyuan.png',
        alt: '能源',
        src: '/agent/nengyuanBg.png',
        title: 'AI+ 能源',
        description: 'AI一体机在能源领域通过集成高性能计算与行业算法，实现实时数据监测与智能分析，优化能源生产调度（如风电/光伏预测）提升电网效率，并支持设备预测性维护与风险预警，以软硬件协同赋能全产业链智能化升级。'
    },
    {
        id: 4,
        icon: '/agent/yiliao.png',
        alt: '医疗',
        src: '/agent/yiliaoBg.png',
        title: 'AI+ 医疗',
        description: 'AI医疗一体机通过集成先进传感器与AI算法，实现血压、血糖、心电等多指标实时监测，结合大模型对数据进行深度分析，提供疾病风险评估、个性化健康管理方案，并支持病历生成、报告解读等智能服务，赋能社区、医院及企业等多场景高效健康管理。'
    },
    {
        id: 5,
        icon: '/agent/jinrong.png',
        alt: '金融',
        src: '/agent/jinrongBg.png',
        title: 'AI+ 金融',
        description: 'AI一体机在金融领域通过硬件与软件深度融合，实现私有化部署，支持智能风控、投顾及自动化报税等场景，其高效算力与数据安全保障助力业务效率提升80%，同时满足合规监管需求，推动金融智能化转型。'
    },
];

function AgentCard() {
    const [activeImageId, setActiveImageId] = useState(null);

    const handleImageHover = (id) => {
        setActiveImageId(id);
    };

    const handleImageLeave = () => {
        setActiveImageId(null);
    };

    return (
        <div className="agent-container">
            <h1>AI+ 行业</h1>
            <div className="image-grid">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className="image-item"
                        onMouseEnter={() => handleImageHover(image.id)}
                        onMouseLeave={handleImageLeave}
                    >
                        <img src={image.src} alt={image.alt} className="image" />
                        <div className='img-mask'>
                            <div className='mask-icon'>
                                <img src={image.icon} alt="" className="icon" />
                            </div>
                            <div className='mask-title'>{image.title}</div>
                        </div>
                        <div className={`overlay ${activeImageId === image.id ? 'active' : ''}`}>
                            <img src={image.icon} alt="" className="icon" />
                            <div className='mask-title'>{image.title}</div>
                            <div className='line'></div>
                            <div className='desc'>{image.description}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AgentCard;